import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import HybridTokens from "@/views/HybridTokens.vue";
import HybridTokensForm from "../views/HybridTokensForm.vue";
import HybridTransactionsForm from "../views/HybridTransactionsForm.vue";
import HybridTransactions from "../views/HybridTransactions.vue";
import HybridSellers from "../views/HybridSellers.vue";
import HybridSellersForm from "../views/HybridSellersForm.vue";
import store from "@/store";
import OTOList from "@/views/components/OTOList.vue";
import OneTrueOrgForm from "@/views/OneTrueOrgForm.vue";
import OneTrueHumanForm from "@/views/OneTrueHumanForm.vue";
import WalletInvoices from "../views/WalletInvoices.vue";
import WalletInvoiceForm from "@/views/WalletInvoiceForm.vue";
import WalletInvoiceView from "@/views/WalletInvoiceView.vue";

import Dashboard56 from "@/views/Dashboard56.vue";

const routes = [
  {
    path: "/vault/test1",
    name: "Vault Test1",
    component: Dashboard56,
    meta: { requiresAuth: false }
  },
  {
    path: "/auth/login",
    name: "Login",
    component: SignIn,
    meta: { requiresAuth: false }
  },
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-hybrid-token",
    name: "Hybrid Assets",
    component: HybridTokens,
    meta: { requiresAuth: true }
  },
  {
    path: "/form-hybrid-token",
    name: "HybridTokensForm",
    component: HybridTokensForm,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-hybrid-transactions",
    name: "Hybrid Transactions",
    component: HybridTransactions,
    meta: { requiresAuth: true }
  },
  {
    path: "/form-hybrid-transactions",
    name: "Create Hybrid Transactions",
    component: HybridTransactionsForm,
    meta: { requiresAuth: true }
  },
  {
    path: "/add-hybrid-sellers",
    name: "Hybrid Sellers",
    component: HybridSellers,
    meta: { requiresAuth: true }
  },
  {
    path: "/organizations",
    name: "One True Organization",
    component: OTOList,
    meta: { requiresAuth: true }
  },
  {
    path: "/form-oto",
    name: "OneTrueOrgForm",
    component: OneTrueOrgForm,
    meta: { requiresAuth: true }
  },
  {
    path: "/form-oth",
    name: "One True Human",
    component: OneTrueHumanForm,
    meta: { requiresAuth: true }
  },
  {
    path: "/form-hybrid-sellers",
    name: "HybridSellersForm",
    component: HybridSellersForm,
    meta: { requiresAuth: true }
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { requiresAuth: true }
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
    meta: { requiresAuth: true }
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    meta: { requiresAuth: false }
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
    meta: { requiresAuth: false }
  },

  {
    path: "/wallet/invoices",
    name: "Wallet Invoices",
    component: WalletInvoices,
    meta: { requiresAuth: true }
  },

  {
    path: "/wallet/invoice/form",
    name: "Wallet Invoice Form",
    component: WalletInvoiceForm,
    meta: { requiresAuth: true }
  },

  {
    path: "/wallet/invoice/:invoice_code/edit",
    name: "Wallet Invoice Edit",
    component: WalletInvoiceForm,
    meta: { requiresAuth: true }
  },

  {
    path: "/wallet/invoice/:invoice_code/view",
    name: "Wallet Invoice View",
    component: WalletInvoiceView,
    meta: { requiresAuth: true }
  },

  {
    path: "/otd/verification",
    name: "Otd Verification",
    component: () => import("@/views/otd/verification/OtdVerification.vue")
  },



  {
    path: "/add-otd",
    name: "One True Data",
    component: () => import("@/views/otd/index/index.vue")
  },
  {
    path: "/form-one-true-data",
    name: "OneTrueDataForm",
    component: () => import("@/views/otd/form/OneTrueDataForm.vue")
  },
  {
    path: "/form-one-true-data/:uuid/show/",
    name: "One True Data Show",
    component: () => import("@/views/otd/form/OneTrueDataForm.vue")
  },


  // {
  //   path: "/add-oth",
  //   name: "One True Human",
  //   component: () => import("@/views/oth/index/index.vue")
  // },
  // {
  //   path: "/form-one-true-human",
  //   name: "OneTrueHumanForm",
  //   component: () => import("@/views/oth/form/OneTrueHumanForm.vue")
  // },
  // {
  //   path: "/form-one-true-human/:uuid/show/",
  //   name: "One True Human Show",
  //   component: () => import("@/views/oth/form/OneTrueHumanForm.vue")
  // },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  const publicPages = ['/auth/login', '/sign-up'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !isAuthenticated) {
    return next('/auth/login');
  }

  next();
});


export default router;
