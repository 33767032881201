/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Import the necessary functions from Vue
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

// Import CSS assets
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";

// Import your custom UI library if needed
import SoftUIDashboard from "./soft-ui-dashboard";

// Import the Loader component
import Loader from "./views/components/Loader.vue";

import Vue3ConfirmDialog from '@zapadale/vue3-confirm-dialog'

// Create the Vue application instance
const appInstance = createApp(App);

// Global registration of the Loader component
appInstance.component('Loader', Loader);

// Use plugins
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(Vue3ConfirmDialog);

// Mount the Vue application
appInstance.mount("#app");

