<template>
  <div>
    <!-- <nav aria-label="breadcrumb">
      <ol
        class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
        :class="this.$store.state.isRTL ? '' : ' me-sm-6'"
      >
        <li class="text-sm breadcrumb-item" :class="textWhite">
          <a
            v-if="this.$store.state.isRTL"
            :class="textWhite"
            class="opacity-5 ps-2"
            href="#"
            >لوحات القيادة</a
          >
          <a v-else :class="textWhite" class="opacity-8" href="#">Pages</a>
        </li>
        <li
          class="text-sm breadcrumb-item active"
          :class="textWhite ? 'text-white' : 'text-dark'"
          aria-current="page"
        >
          {{ currentPage }}
        </li>
      </ol>
      <h6 class="mb-0 font-weight-bolder" :class="textWhite ? 'text-white' : ''">
        {{ currentPage }}
      </h6>
    </nav> -->

    <h4 style="color:#73818F"><i class="fa fa-th-large " ></i><span class="mx-2">{{currentPage}}</span></h4>



  </div>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
};
</script>
