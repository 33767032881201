<template>
  <div class="card h-100 mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Invoices</h6>
        </div>
        <div class="col-6 text-end">
          <!-- <router-link :to="{ name: 'Wallet Invoices', params: {} }" class="btn btn-outline-success text-xs" >View All</router-link> -->
          <router-link :to="{ name: 'Wallet Invoice Form', params: {} }" class="btn bg-gradient-warning mx-1 w-35" v-if="[1,7].includes($store.getters.currentUser.role)">Add</router-link>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code
              </th>
               <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                User
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Emission
              </th>



               <!-- <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Due Date
              </th> -->
             
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Price
              </th>
               <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Expires At
              </th>


               <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
               <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Transaction
              </th> 

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in walletInvoices" :key="index">
            <td>            
              <span class="text-xs">#{{item.invoice_code}}</span>
            </td>
            <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.user_name ? item.user_name : '- - -'}}</span>
            </td>
            <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.emission_date ? formatDate(item.emission_date) : '- - -'}}</span>
            </td>

           
             <!-- <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{item.due_date ? formatDate(item.due_date) : '- - -'}}</span>
            </td> -->

           

            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{item.currencies}} {{item.price}}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{item.amount + (item.coin=='LoopZ' ? " LoopZ's" : (item.coin=='OrbX' ? " OrbX's" : 'Coins') )}}</span>
            </td>

             <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.expires_at ? formatDateTime(item.expires_at) : '- - -'}}</span>
            </td>

            <td class="align-middle text-center text-sm">
              <soft-badge color="warning" variant="gradient" size="sm" class="" v-if="item.status=='Pending'">{{item.status}}</soft-badge >
              <soft-badge color="success" variant="gradient" size="sm" class="" v-else-if="item.status=='Paid'">{{item.status}}</soft-badge >
              <soft-badge color="danger" variant="gradient" size="sm" class="" v-else-if="item.status=='Canceled'">{{item.status}}</soft-badge >
              <soft-badge color="dark" variant="gradient" size="sm" class="" v-else-if="item.status=='Expired'">{{item.status}}</soft-badge >
              <span class="text-secondary text-xs font-weight-bold" v-else>{{item.status}}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <!-- <span class="text-secondary text-xs font-weight-bold">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</span> -->
              <soft-badge color="warning" variant="gradient" size="sm" class="" v-if="item.wallet_transaction=='Pending'">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</soft-badge >
              <soft-badge color="secondary" variant="gradient" size="sm" class="" v-else-if="item.wallet_transaction=='Processing'">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</soft-badge >
              <soft-badge color="success" variant="gradient" size="sm" class="" v-else-if="item.wallet_transaction=='Completed'">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</soft-badge >
              <soft-badge color="danger" variant="gradient" size="sm" class="" v-else-if="item.wallet_transaction=='Canceled'">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</soft-badge >
              <span class="text-secondary text-xs font-weight-bold" v-else>{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</span>
            </td>


            <td class="align-middle text-center">

              
              <!-- <i class="fas fa-link mx-2 text-xs text-dark " @click="accessPaymentLink(item.payment_link, item.invoice_code)" style="cursor:pointer" v-if="item.payment_link && item.status=='Pending'"/> -->
              <!-- <a target="_blank" :href="item.payment_link" class="text-secondary text-xs text-dark font-weight-bold mx-2" v-if="item.payment_link && item.status=='Pending'" ><i class="fas fa-link"/></a> -->
              <!-- <i class="fas fa-link mx-2 text-xs text-secondary" v-else/> -->
              
              <router-link :to="{ name: 'Wallet Invoice View', params: {'invoice_code':  item.invoice_code} }" class="text-secondary text-xs text-dark font-weight-bold mx-2" ><i class="fas fa-eye "/></router-link>

              <router-link v-if="[1,7].includes($store.getters.currentUser.role)" :to="{ name: 'Wallet Invoice Edit', params: {'invoice_code':  item.invoice_code} }" class="text-secondary text-xs text-dark font-weight-bold mx-2" ><i class="fas fa-edit "/></router-link>
              
       
              <!-- <i class="fas fa-times-circle mx-2 text-xs text-dark " @click="confirmCancelInvoice(item.invoice_code)" style="cursor:pointer" v-if="[1,7].includes($store.getters.currentUser.role) && item.status=='Pending'"/>
              <i class="fas fa-times-circle mx-2 text-xs text-secondary" v-else/> -->

              
              <i class="fas fa-trash mx-2 text-xs text-dark " @click="confirmDelete(item.invoice_code)" style="cursor:pointer" v-if="[1,7].includes($store.getters.currentUser.role)"/>
              <i class="fas fa-trash mx-2 text-xs text-secondary" v-else/>

            </td>
          </tr>
          </tbody>

        </table>

                
        <div class="d-flex align-items-end justify-content-end mt-4 " >
          <!-- <nav aria-label="..." class="mt-4">
            
            <ul class="pagination">
              <li :class="`page-item ${current_page<=1?'disabled':''}`">
                <span class="page-link" @click="prevPage()">Prev</span>
              </li>
  
              <li :class="`btn bg-gradient-danger mt-4 w-100 ${current_page>=last_page?'disabled':''}`" >
                <a class="page-link" href="#" @click="nextPage()">Next</a>
              </li>
            </ul>
          </nav> -->

          <button class="btn bg-gradient-warning mx-1" type="button" @click="prevPage()" :disabled="current_page<=1">Prev</button>
          <button class="btn bg-gradient-warning mx-1" type="button" @click="nextPage()" :disabled="current_page>=last_page">Next</button>
        </div>




        <div class="align-middle text-center text-secondary text-xs my-4" v-if="walletInvoices.length==0">No Invoices</div>

      </div>
    </div>
  </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';
import moment from 'moment'
import SoftBadge from "@/components/SoftBadge.vue";

export default {
  name: "InvoiceCard",
  components: {
    SoftBadge,
  },
  mounted() {
    this.fetchInvoices();
  },
  data() {
    return {
      current_page: 1,
      last_page: 1,
      walletInvoices: []
    }
  },
  methods: {

    confirmDelete(code) {
      this.$root.confirm('Confirm your action', `Do you confirm the deletion of ${code}?`, 'Yes', 'No', () => this.handleDelete(code) );
    },

    handleDelete(code) {
      let self = this;
      axios.delete(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/${code}/delete`).then(response => {
        if (response.data.status == 'success') {
          self.fetchInvoices();
          self.$root.notification('', response.data.message)
        } else {
          self.$root.notification('', response.data.message)
        }          
      }).catch(error => {
        if (error.response.data.message) {
          self.$root.notification('', error.response.data.message)
        } else {
          console.log(error);
        }        
      }); 
    },

    prevPage() {
      this.current_page --;
      this.fetchInvoices();
    },
    nextPage() {
      this.current_page++;
      this.fetchInvoices();
    },

    fetchInvoices() {
      let self = this;
        axios.get(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/open?page=${self.current_page}`).then(response => {
          // console.log('Wallet Invoices', response.data)
          if (response.data.status == 'success') {
            self.walletInvoices = response.data.data.data
            self.current_page = response.data.data.current_page
            self.last_page = response.data.data.last_page
          }          
        })
        .catch(error => {
          console.log(error);
        });
 
    },


    accessPaymentLink(url, code) {
      this.$root.confirm('Confirm your action', `Do you want to access the ${code} code payment link?`, 'Yes', 'No', () => window.open(url, '_blank').focus() );
    },

    // confirmCancelInvoice(code) {
    //   this.$root.confirm('Confirm your action', `Do you confirm the cancel of ${code}?`, 'Yes', 'No', () => this.cancelInvoice(code) );
    // },

    // cancelInvoice(code) {
    //   let self = this;

    //   // self.loading = true;
      
    //   axios.post(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/cancel`, {'invoice_code': code}).then(response => {
    //     console.log(`Wallet Invoice cancel ${code}`, response.data)
    //     if (response.data.status == 'success') {
    //       self.fetchInvoices();
    //     } else {
    //       self.$root.notification('', response.data.message)
    //     }          
    //   })
    //   .catch(error => {
    //     if (error.response.data.message) {
    //       self.$root.notification('', error.response.data.message)
    //     } else {
    //       console.log(error);
    //     }        
    //   })
    //   .finally(() => {
    //     // self.loading = false;
    //   });

    // },

    formatDate(date) {
      return moment(date).format('LL')
    },
    formatDateTime(date) {
      return moment(date).format('LLL')
    }

  }

};
</script>
