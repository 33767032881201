<template>
  <footer class="py-3 footer">
    <!-- <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, made by
            <a
              href="https://www.codeloop.co"
              class="font-weight-bold"
              target="_blank"
              >CodeLoop Corporation</a
            >
            for a better world.
          </div>
        </div>

      </div>
    </div> -->
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
