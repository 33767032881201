<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h2 class="mb-4">
            Wallet Invoice {{ formData.invoice_code ? "Edit" : "Create" }}
          </h2>
        </div>
        <div class="col-6 text-end">
          <router-link
            :to="{ path: '/billing', params: {} }"
            class="btn bg-gradient-warning"
            >Back</router-link
          >
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="row">
        <div class="col-12 col-lg-4 mb-4">
          <div
            v-if="formData.coin == 'LoopZ'"
            class="d-flex align-items-center justify-content-center"
          >
            <img
              src="https://mainland.codeloop.co/img/LoopZ_coin.png"
              alt="img LoopZ"
              style="width: 100%; max-width: 300px"
            />
          </div>
          <div
            v-else-if="formData.coin == 'OrbX'"
            class="d-flex align-items-center justify-content-center"
          >
            <img
              src="https://mainland.codeloop.co/img/OrbX_coin.png"
              alt="img OrbX"
              style="width: 100%; max-width: 300px"
            />
          </div>

          <div class="text-center mt-4">
            <h5 class="font-weight-bolder">
              {{
                formData.coin == "LoopZ"
                  ? "LoopZ"
                  : formData.coin == "OrbX"
                  ? "OrbX"
                  : "Coins"
              }}
            </h5>

            <p>
              You are purchasing
              <span class="font-weight-bolder">{{
                formData.price ? parseInt(formData.price) : 0
              }}</span>
              {{
                formData.coin == "LoopZ"
                  ? "Loop Coins"
                  : formData.coin == "OrbX"
                  ? "OrbX"
                  : "Coins"
              }}.
            </p>
          </div>
        </div>

        <div class="col-12 col-lg-8">
          <h6 class="mb-3" v-if="formData.invoice_code">
            #{{ formData.invoice_code }}
          </h6>

          <form @submit.prevent="confirmSubmitForm">

            <div class="mb-3">
              <label for="customer" class="form-label required">Customer</label>

              <input
                v-if="formData.invoice_code"
                class="form-control"
                :value="formData.customer"
                disabled
              />
              <select
                id="customer"
                v-model="formData.user_id"
                class="form-select"
                :disabled="formData.invoice_code || loading"
                required
                v-else
              >
                <option disabled selected v-if="usersList.length == 0">
                  Empty
                </option>
                <option disabled selected v-else value="">Select</option>
                <option v-for="(s, i) in usersList" :key="i" :value="s.value">
                  {{ s.text }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="description" class="form-label required"
                >Description:</label
              >
              <textarea
                type="description"
                id="description"
                class="form-control"
                required
                v-model="formData.description"
                placeholder="Description"
                :disabled="formData.invoice_code || loading"
              ></textarea>
            </div>

            <div class="row">
              <div class="col col-sm-12">
                <label for="currencies" class="form-label">Coin</label>
                <select
                  id="currencies"
                  v-model="formData.coin"
                  class="form-select"
                  :disabled="loading || formData.status == 'Paid'"
                >
                  <option selected value="LoopZ">LoopZ</option>
                  <option selected value="OrbX">OrbX</option>
                </select>
              </div>

              <div class="col col-sm-4">
                <label for="currencies" class="form-label">Mode</label>
                <select
                  id="mode"
                  v-model="formData.mode"
                  class="form-select"
                  :disabled="loading || formData.status == 'Paid'"
                >
                  <option selected value="payment-direct">
                    Direct payment
                  </option>
                  <option selected value="payment-link">With Link</option>
                </select>
              </div>

              <div class="col col-sm-4">
                <label for="currencies" class="form-label">Currencies</label>
                <select
                  id="currencies"
                  v-model="formData.currencies"
                  class="form-select"
                  :disabled="loading || formData.status == 'Paid'"
                >
                  <option selected value="USD">USD</option>
                  <option selected value="EUR">EUR</option>
                  <option selected value="BRL">BRL</option>
                </select>
              </div>

              <div class="col col-sm-4">
                <label for="price" class="form-label required">Price:</label>

                <money3
                  v-model="formData.price"
                  class="form-control"
                  v-bind="configMoney"
                  :disabled="loading || formData.status == 'Paid'"
                ></money3>
              </div>

              <div class="col col-sm-4" v-if="formData.invoice_code">
                <label for="status" class="form-label required"
                  >Payment Status:</label
                >
                <select
                  id="status"
                  v-model="formData.status"
                  required
                  class="form-select"
                  :disabled="loading || formData.status == 'Paid'"
                >
                  <option
                    v-for="(s, i) in statusList"
                    :key="i"
                    :value="s.value"
                    :disabled="!formData.invoice_code"
                  >
                    {{ s.text }}
                  </option>
                </select>
              </div>

              <div class="col col-sm-4" v-if="formData.invoice_code">
                <label for="payment_date" class="form-label"
                  >Payment Date:</label
                >
                <input
                  type="date"
                  id="payment_date"
                  class="form-control"
                  v-model="formData.payment_date"
                  placeholder="Ex: 0.00"
                  :disabled="
                    loading ||
                    formData.status == 'Paid' ||
                    formData.status == 'Pending'
                  "
                />
              </div>

              <div class="col col-sm-4" v-if="formData.invoice_code">
                <label for="wallet_transaction" class="form-label required"
                  >Transaction:</label
                >
                <select
                  id="wallet_transaction"
                  v-model="formData.wallet_transaction"
                  required
                  class="form-select"
                  :disabled="loading || formData.status == 'Pending'"
                >
                  <option selected value="Pending">Pending</option>
                  <option selected value="Processing">Processing</option>
                  <option selected value="Completed">Completed</option>
                </select>
              </div>
            </div>

            <div
              v-if="
                formData.invoice_code &&
                [1, 7].includes($store.getters.currentUser.role)
              "
            >
            </div>

            <button
              class="btn bg-gradient-warning w-100 mt-5"
              type="submit"
              :disabled="loading"
              v-if="formData.status == 'Pending'"
            >
              {{
                loading
                  ? "Wait..."
                  : formData.invoice_code
                  ? "Update Invoice"
                  : "Create Invoice"
              }}
            </button>

            <button
              class="btn bg-gradient-success w-100 mt-5"
              type="button"
              @click="confirmWalletStart(formData.invoice_code)"
              :disabled="
                formData.wallet_status == 200 || formData.status != 'Paid'
              "
              v-if="
                formData.status == 'Paid' &&
                [null, 'erro'].includes(formData.wallet_status)
              "
            >
              {{ formData.wallet_status == "erro" ? "Retry " : "" }} Proccess to
              Wallet
            </button>
          </form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Money3Component } from "v-money3";

export default {
  name: "RegisterHumanProfile",
  components: {
    money3: Money3Component,
  },
  mounted() {
    // alert(process.env.NODE_ENV);
    // alert(process.env.VUE_APP_TITLE);
    // alert(process.env.VUE_APP_API_URl);

    if (this.$route.params.invoice_code) {
      this.handleShowInvoice();
    } else {
      this.fetchUsers();
    }
  },

  data() {
    return {
      loading: false,
      usersList: [],

      configMoney: {
        masked: false,
        prefix: "",
        suffix: "",
        thousands: ",",
        decimal: ".",
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },

      token: "",
      statusList: [
        { value: "Pending", text: "Pending" },
        { value: "Paid", text: "Paid" },
        { value: "Canceled", text: "Canceled" },
      ],
      formData: {
        user_id: "",
        // Dados da Fatura (Invoice)
        invoice_code: "",
        emission_date: "",

        coin: "LoopZ",

        due_date: "",
        description: "",
        quantity: "1",
        price: "",
        total: "",
        status: "Pending",

        mode: "payment-direct",

        payment_method: "",
        payment_status: "",
        payment_id: "",
        payment_total: "",
        fees_applied: "",
        payment_date: "",
        payment_link: "",

        currencies: "USD",
        additional_notes: "",
      },
      messages: {},
    };
  },
  methods: {

    fetchUsers() {
      let self = this;
      axios
        .get(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/users`)
        .then((response) => {
          if (response.data.status == "success") {
            self.usersList = response.data.data;
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },

    handleShowInvoice() {
      let self = this;
      axios
        .get(
          `${process.env.VUE_APP_API_URl}/api/wallet/invoices/${self.$route.params.invoice_code}/show`
        )
        .then((response) => {
          if (response.data.status == "success") {
            self.formData = response.data.data;
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },

    confirmWalletStart(code) {
      this.$root.confirm(
        "Confirm your action",
        `Do you confirm the insertion of loopcoins for code ${code}?`,
        "Yes",
        "No",
        () => this.startWalletProccess(code)
      );
    },
    startWalletProccess(code) {
      let self = this;

      // self.loading = true;

      axios
        .post(
          `${process.env.VUE_APP_API_URl}/api/wallet/invoices/process/coin`,
          { invoice_code: code }
        )
        .then((response) => {
          if (response.data.status == "success") {
            // self.fetchInvoices();
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          self.handleShowInvoice();
        });
    },

    confirmCancelInvoice(code) {
      this.$root.confirm(
        "Confirm your action",
        `Do you confirm the cancel of ${code}?`,
        "Yes",
        "No",
        () => this.cancelInvoice(code)
      );
    },
    cancelInvoice(code) {
      let self = this;

      axios
        .post(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/cancel`, {
          invoice_code: code,
        })
        .then((response) => {
          console.log(`Wallet Invoice cancel ${code}`, response.data);
          if (response.data.status == "success") {
            self.handleShowInvoice();
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        })
        .finally(() => {});
    },

    confirmDelete(code) {
      this.$root.confirm(
        "Confirm your action",
        `Do you confirm the deletion of ${code}?`,
        "Yes",
        "No",
        () => this.handleDelete(code)
      );
    },

    handleDelete(code) {
      let self = this;
      axios
        .delete(
          `${process.env.VUE_APP_API_URl}/api/wallet/invoices/${code}/delete`
        )
        .then((response) => {
          if (response.data.status == "success") {
            self.$router.replace({ path: "/billing", query: {} });
            self.$root.notification("", response.data.message);
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    confirmSubmitForm() {
      this.$root.confirm(
        "Confirm your action",
        `Do you confirm the creation of this item?`,
        "Yes",
        "No",
        () => this.submitForm()
      );
    },

    async submitForm() {
      let self = this;

      self.loading = true;

      let method = self.formData.invoice_code
        ? axios.put(
            `${process.env.VUE_APP_API_URl}/api/wallet/invoices/${self.formData.invoice_code}/update`,
            self.formData
          )
        : axios.post(
            `${process.env.VUE_APP_API_URl}/api/wallet/invoices/store`,
            self.formData
          );

      await method
        .then((response) => {
          if (response.data.status == "success") {
            self.$router.replace({ path: "/billing", query: {} });
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        })
        .finally(() => (self.loading = false));
    },
  },
};
</script>