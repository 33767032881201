<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h2 class="mb-4">Wallet Invoice View</h2>
        </div>
        <div class="col-6 text-end">
          <router-link
            :to="{ path: '/billing', params: {} }"
            class="btn bg-gradient-warning"
            >Back</router-link
          >
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-4 mb-4">
          <div
            v-if="formData.coin == 'LoopZ'"
            class="d-flex align-items-center justify-content-center"
          >
            <img
              src="https://mainland.codeloop.co/img/LoopZ_coin.png"
              alt="img LoopZ"
              style="width: 100%; max-width: 300px"
            />
          </div>
          <div
            v-else-if="formData.coin == 'OrbX'"
            class="d-flex align-items-center justify-content-center"
          >
            <img
              src="https://mainland.codeloop.co/img/OrbX_coin.png"
              alt="img OrbX"
              style="width: 100%; max-width: 300px"
            />
          </div>

          <div class="text-center mt-4">
            <h5 class="font-weight-bolder">
              {{
                formData.coin == "LoopZ"
                  ? "Loop Coins"
                  : formData.coin == "OrbX"
                  ? "OrbX"
                  : "Coins"
              }}
            </h5>

            <p>
              You are purchasing
              <span class="font-weight-bolder">{{
                formData.price ? parseInt(formData.price) : 0
              }}</span>
              {{
                formData.coin == "LoopZ"
                  ? "Loop Coins"
                  : formData.coin == "OrbX"
                  ? "OrbX"
                  : "Coins"
              }}.
            </p>
          </div>
        </div>

        <div class="col-12 col-lg-8">
          <h6 class="mb-3" v-if="formData.invoice_code">
            <strong>#{{ formData.invoice_code }}</strong>
          </h6>

          <form>

            <div class="mb-3">
              <label for="customer" class="form-label">Customer:</label>

              <input
                v-if="formData.invoice_code"
                class="form-control"
                :value="formData.customer"
                disabled
              />
              <select
                id="customer"
                v-model="formData.user_id"
                class="form-select"
                :disabled="formData.invoice_code || loading"
                required
                v-else
              >
                <option disabled selected v-if="usersList.length == 0">
                  Empty
                </option>
                <option disabled selected v-else value="">Select</option>
                <option v-for="(s, i) in usersList" :key="i" :value="s.value">
                  {{ s.text }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="description" class="form-label">Description:</label>
              <textarea
                type="description"
                id="description"
                class="form-control"
                required
                v-model="formData.description"
                placeholder="Description"
                :disabled="formData.invoice_code || loading"
              ></textarea>
            </div>

            <div class="row">
              <div class="col col-sm-4">
                <label for="currencies" class="form-label">Coin:</label>
                <select
                  id="currencies"
                  v-model="formData.coin"
                  class="form-select"
                  disabled
                >
                  <option selected value="LoopZ">LoopZ</option>
                  <option selected value="OrbX">OrbX</option>
                </select>
              </div>

              <div class="col col-sm-4">
                <label for="currencies" class="form-label">Currencies:</label>
                <select
                  id="currencies"
                  v-model="formData.currencies"
                  class="form-select"
                  disabled
                >
                  <option selected value="USD">USD</option>
                  <option selected value="EUR">EUR</option>
                  <option selected value="BRL">BRL</option>
                </select>
              </div>

              <div class="col col-sm-4">
                <label for="price" class="form-label">Price:</label>
                <input
                  type="text"
                  id="price"
                  class="form-control"
                  required
                  v-model="formData.price"
                  placeholder="Ex: 0.00"
                  disabled
                />
              </div>

              <div class="col col-sm-4" v-if="formData.invoice_code">
                <label for="status" class="form-label">Payment Status:</label>
                <select
                  id="status"
                  v-model="formData.status"
                  required
                  class="form-select"
                  disabled
                >
                  <option
                    v-for="(s, i) in statusList"
                    :key="i"
                    :value="s.value"
                    :disabled="!formData.invoice_code"
                  >
                    {{ s.text }}
                  </option>
                </select>
              </div>

              <div class="col col-sm-4">
                <label for="payment_date" class="form-label"
                  >Payment Date:</label
                >
                <input
                  type="date"
                  id="payment_date"
                  class="form-control"
                  v-model="formData.payment_date"
                  placeholder="Ex: 0.00"
                  disabled
                />
              </div>

              <div class="col col-sm-4" v-if="formData.invoice_code">
                <label for="wallet_transaction" class="form-label"
                  >Transaction:</label
                >
                <select
                  id="wallet_transaction"
                  v-model="formData.wallet_transaction"
                  required
                  class="form-select"
                  disabled
                >
                  <option selected value="Pending">Pending</option>
                  <option selected value="Processing">Processing</option>
                  <option selected value="Completed">Completed</option>
                </select>
              </div>
            </div>

            <div
              v-if="
                formData.invoice_code &&
                [1, 7].includes($store.getters.currentUser.role)
              "
            >

            </div>
          </form>

          <div class="mt-5" v-if="formData.invoice_code">
            <h4 class="mb-0">Payment</h4>

            <div v-if="formData.mode == 'payment-direct'">
              <div v-if="formData.status == 'Pending'">
                <p class="mt-0 small">
                  Enter the details below to pay this invoice
                </p>
                <StripeCheckout
                  :amount="formData.price"
                  backendUrl="/api/stripe/checkout-success"
                  :id_code="formData.invoice_code"
                />
              </div>
            </div>

            <div v-else-if="formData.mode == 'payment-link'">
              <p class="mt-0 small">
                Use the button below to be redirected to the payment link
              </p>

              <button
                v-if="!formData.payment_link"
                class="btn bg-gradient-success w-100"
                type="button"
                disabled
              >
                Link to payment unavailable
              </button>
              <a
                v-else
                target="_blank"
                :href="formData.payment_link"
                class="btn bg-gradient-success w-100"
                :disabled="formData.payment_link"
                >Link to payment</a
              >
            </div>

            <SoftAlert
              :message="`Payment made successfully in ${formData.payment_date}`"
              v-else-if="formData.status == 'Paid'"
              color="success"
              :dismissible="false"
            ></SoftAlert>

            <SoftAlert
              message="This invoice is expired"
              v-else-if="formData.status == 'Expired'"
              color="dark"
              :dismissible="false"
            ></SoftAlert>

            <SoftAlert
              message="This invoice is canceled"
              v-else-if="formData.status == 'Canceled'"
              color="danger"
              :dismissible="false"
            ></SoftAlert>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import SoftAlert from "@/components/SoftAlert.vue";
import StripeCheckout from "./StripeCheckout.vue";

export default {
  name: "RegisterHumanProfile",
  components: {
    SoftAlert,
    StripeCheckout,
  },
  mounted() {
    if (this.$route.params.invoice_code) {
      this.handleShowInvoice();
    }
  },

  data() {
    return {
      loading: false,
      usersList: [],

      token: "",
      statusList: [
        { value: "Pending", text: "Pending" },
        { value: "Paid", text: "Paid" },
        { value: "Canceled", text: "Canceled" },
      ],
      formData: {
        user_id: "",

        invoice_code: "",
        emission_date: "",

        coin: "LoopZ",

        due_date: "",
        description: "",
        quantity: "1",
        price: "",
        total: "",
        status: "Pending",

        payment_method: "",
        payment_status: "",
        payment_id: "",
        payment_total: "",
        fees_applied: "",
        payment_date: "",
        payment_link: "",

        currencies: "USD",
        additional_notes: "",
      },
      messages: {},
    };
  },
  methods: {
    handleShowInvoice() {
      let self = this;
      axios
        .get(
          `${process.env.VUE_APP_API_URl}/api/wallet/invoices/${self.$route.params.invoice_code}/show`
        )
        .then((response) => {
          if (response.data.status == "success") {
            self.formData = response.data.data;
          } else {
            self.$root.notification("", response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            self.$root.notification("", error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>
