<template>

  <div class="container-fluid mt-4">
    <!-- <SoftAlert
        icon="fas fa-thumbs-up"
        v-if="true"
        :message="message" 
        color="danger" /> -->
        
    <!-- <soft-button color="white" variant="outline" active="false" class="mb-3">
      <i class="fas fa-coins me-2"></i>
      Buy Loop Coins
    </soft-button> -->
    <div class="row align-items-center">
      <div class="mt-4 col-lg-6 col-sm-6 mt-sm-0">
        <div class="mb-4 card">

          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div>
                <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                    class="text-lg opacity-10 ni ni-money-coins" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-capitalize font-weight-bold">LoopZ Balance</p>
                  <h5 class="mb-0 font-weight-bolder">{{ balance ? balance : 0 }} <span class="text-sm font-weight-bolder loop-color">
                    </span></h5>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div>
                <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                    class="text-lg opacity-10 ni ni-money-coins" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-capitalize font-weight-bold">Aura Coins's Balance</p>
                  <h5 class="mb-0 font-weight-bolder">0<span class="text-sm font-weight-bolder text-success"> </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-6 col-sm-6 mt-sm-0">
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div>
                <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                    class="text-lg opacity-10 ni ni-paper-diploma" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-capitalize font-weight-bold">Aura Gem's Balance</p>
                  <h5 class="mb-0 font-weight-bolder">0<span class="text-sm font-weight-bolder text-danger"> </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div>
                <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                    class="text-lg opacity-10 ni ni-money-coins" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-capitalize font-weight-bold">Warden Coins's Balance</p>
                  <h5 class="mb-0 font-weight-bolder">0 <span class="text-sm font-weight-bolder text-success">
                    </span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">

          <!-- <div class="col-xl-6">
            
          </div> -->
          <!-- <div class="col-md-12 mb-4">
            <payment-methods-card />
          </div> -->
        </div>
      </div>
      <div class="col-lg-12">
        <invoice-card />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-7 mt-4">
        <!-- <billing-card title="Financial Information" :bills="[
          {
            name: this.$store.getters.currentUser?.name || 'Guest',
            company: 'Versado',
            email: this.$store.getters.currentUser?.email || 'Guest',
            id: 'FRB1235476',
          }
        ]" /> -->

        <billing-card title="Financial Information" :bills="[
          {
            name: this.$store.getters.currentUser?.name || 'Guest',
       
            email: this.$store.getters.currentUser?.email || 'Guest',
         
          }
        ]" />
        
      </div>
      <div class="col-md-5 mt-4">
        <ranking-list-card :horizontal-break="false" :card="{
          title: 'Transactions',
          date: '01 - 30 May 2024',
          subtitle: 'Newest',
        }" :item="[
          
        ]">
          <ranking-list title="Yesterday" :item="[
            
          ]" :horizontal-break="false" />
        </ranking-list-card>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
// import NavPill from "./components/NavPill.vue";
// import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
// import PaymentMethodsCard from "./components/PaymentMethodsCard.vue";
import InvoiceCard from "./components/InvoiceCard.vue";
import BillingCard from "./components/BillingCard.vue";
import RankingList from "@/examples/Cards/RankingList.vue";
import RankingListCard from "@/examples/Cards/RankingListCard.vue";
// import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';
// import SoftAlert from "../components/SoftAlert.vue";


export default {
  name: "Billing",
  components: {
    // NavPill,
    // DefaultInfoCard,
    // PaymentMethodsCard,
    // SoftButton,
    InvoiceCard,
    BillingCard,
    RankingList,
    RankingListCard,
    // SoftAlert,
  },
  data() {
    return {
      balance: 0,
      wallet: '',
      message: 'Attention, Loop Coins deposits are disabled.',
    };
  },
  mounted() {
    setTooltip();
  },
  methods: {
    async fetchBalance() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URl}/api/wallet`, {
          // headers: {
          //   Authorization: `Bearer ${this.$store.state.token}`
          // }
        });
        // console.log({response})
        this.balance = response.data.wallet;
        // console.log({response})
        // console.log(this.balance)
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    async searchWallet() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URl}/api/bank`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        });
        this.wallet = response.data.bank.wallet.wallet_id;
        if (this.wallet != ''){
          this.fetchBalance();
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    }
  },
  created() {
    this.searchWallet();
  }
};
</script>
<style>
.loop-color {
  background-color: #FF8D26;
}
</style>

