<template>
  <!-- <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  /> -->
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- DIALOGO DE NOTIFICAÇÔES E CONFIRMAÇOES -->
    <vue3-confirm-dialog/>

    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="!['/auth/login'].includes($route.path) && this.$store.state.showNavbar"
    />
    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
    <!--<configurator-->
    <!--  :toggle="toggleConfigurator"-->
    <!--  :class="[-->
    <!--    this.$store.state.showConfig ? 'show' : '',-->
    <!--    this.$store.state.hideConfigButton ? 'd-none' : '',-->
    <!--  ]"-->
    <!--/>-->
  </main>
</template>
<script>
// import Sidenav from "./examples/Sidenav";
//import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar2.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import axios from "axios";
export default {
  name: "App",
  components: {
    // Sidenav,
//    Configurator,
    Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    notification(title, message) {
      this.$confirm(
        {
          title: title ? title : 'Information',
          message: message,
          disableKeys: false,
          auth: false,
          button: {
            no: 'OK',
          }
        }
      )
    },
    confirm(title, message, t_yes, t_no, callbackYes, callbackNo=null) {
      this.$confirm(
        {
          title: title,
          message: message,
          disableKeys: false,
          auth: false,
          button: {
            no: t_no,
            yes: t_yes
          },
          /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
              if ( callbackYes ) {
                callbackYes();
              }
              
            } else {
              if ( callbackNo ) {
                callbackNo();
              }
            }
          }
        }
      )
    }
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  created() {
    if (this.$store.state.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.token}`;
    }
  }
};
</script>
