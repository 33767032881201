<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex flex-column h-100">
                  <h5 class="font-weight-bolder">Welcome to Bastion, {{ userName }}!</h5>


            

                  <StripeCheckout title="Venture Carmesin" :amount="19.94" backendUrl="" class="mt-4"/>
        
                 
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
     
  
    </div>
   
  </div>
</template>
<script>
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";

import axios from 'axios';

import StripeCheckout from './StripeCheckout.vue'

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  computed: {
    userName() {
      return this.$store.getters.currentUser?.name || 'Guest';
    }
  },
  data() {
    return {
      iconBackground: "bg-gradient-warning",
      iframeSrc: 'https://blockchain.codeloop.co:4433/explorer/nodes',
      iframeLoaded: false,
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
      balance: 0,
      wallet: '',
    };
  },
  methods: {
    async fetchBalance() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URl}/api/wallet`, {
          // headers: {
          //   Authorization: `Bearer ${this.$store.state.token}`
          // }
        });
        // console.log({response})
        this.balance = response.data.wallet;
        // console.log({response})
        // console.log(this.balance)
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    async searchWallet() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URl}/api/bank`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        });
        this.wallet = response.data.bank.wallet.wallet_id;
        if (this.wallet != '') {
          this.fetchBalance();
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    }
  },
  created() {
    this.searchWallet();
  },
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    // GradientLineChart,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
    StripeCheckout
  },
};
</script>
<style>
.bg-gradient-orange {
  background-image: linear-gradient(310deg, #FFA500 0%, #FF8C00 50%, #FF4500 100%);
}
</style>