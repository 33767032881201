<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex flex-column h-100">
                  <h5 class="font-weight-bolder">Welcome to Bastion, {{ userName }}!</h5>
                  <p class="mb-1 pt-2 text-bold">Enjoy your journey through a cashless society</p>
                  <div class="row align-items-center">
                    <div class="mt-4 col-lg-6 col-sm-6 mt-sm-0">
                      <div class="mb-4 card">

                        <div class="p-3 card-body">
                          <div class="d-flex flex-row-reverse justify-content-between">
                            <div>
                              <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                                  class="text-lg opacity-10 ni ni-money-coins" aria-hidden="true"></i></div>
                            </div>
                            <div class="">
                              <div class="numbers">
                                <p class="mb-0 text-sm text-capitalize font-weight-bold">Loop Coins's Balance</p>
                                <h5 class="mb-0 font-weight-bolder">{{ balance ? balance : 0 }} <span
                                    class="text-sm font-weight-bolder loop-color">
                                  </span></h5>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 card">
                        <div class="p-3 card-body">
                          <div class="d-flex flex-row-reverse justify-content-between">
                            <div>
                              <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                                  class="text-lg opacity-10 ni ni-money-coins" aria-hidden="true"></i></div>
                            </div>
                            <div class="">
                              <div class="numbers">
                                <p class="mb-0 text-sm text-capitalize font-weight-bold">Aura Coins's Balance</p>
                                <h5 class="mb-0 font-weight-bolder">0<span
                                    class="text-sm font-weight-bolder text-success"> </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4 col-lg-6 col-sm-6 mt-sm-0">
                      <div class="mb-4 card">
                        <div class="p-3 card-body">
                          <div class="d-flex flex-row-reverse justify-content-between">
                            <div>
                              <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                                  class="text-lg opacity-10 ni ni-paper-diploma" aria-hidden="true"></i></div>
                            </div>
                            <div class="">
                              <div class="numbers">
                                <p class="mb-0 text-sm text-capitalize font-weight-bold">Aura Gem's Balance</p>
                                <h5 class="mb-0 font-weight-bolder">0<span
                                    class="text-sm font-weight-bolder text-danger"> </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 card">
                        <div class="p-3 card-body">
                          <div class="d-flex flex-row-reverse justify-content-between">
                            <div>
                              <div class="text-center shadow icon icon-shape border-radius-md loop-color"><i
                                  class="text-lg opacity-10 ni ni-money-coins" aria-hidden="true"></i></div>
                            </div>
                            <div class="">
                              <div class="numbers">
                                <p class="mb-0 text-sm text-capitalize font-weight-bold">Warden Coins's Balance</p>
                                <h5 class="mb-0 font-weight-bolder">0 <span
                                    class="text-sm font-weight-bolder text-success">
                                  </span></h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;">
                    READ MORE ABOUT
                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </a> -->
                </div>
              </div>
              <!-- <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-orange border-radius-lg h-100">
                  <img src="../assets/img/shapes/waves-white.svg"
                    class="position-absolute h-100 w-50 top-0 d-lg-block d-none" alt="waves" />
                  <div class="position-relative d-flex align-items-center justify-content-center h-100">
                    <img class="w-100 position-relative z-index-2 pt-4"
                      src="../assets/img/illustrations/rocket-white.png" alt="rocket" />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Loop Coin" value="$53,000" :percentage="{
          value: '+505%',
          color: 'text-success',
        }" :icon="{
                    component: 'ni ni-money-coins',
                    background: iconBackground,
                  }" direction-reverse />
        <mini-statistics-card title="Coffee Tokens" value="$2,300" :percentage="{
          value: '+3%',
          color: 'text-success',
        }" :icon="{
                    component: ' ni ni-world',
                    background: iconBackground,
                  }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Oil Token" value="$3,462" :percentage="{
          value: '-2%',
          color: 'text-danger',
        }" :icon="{
                    component: 'ni ni-paper-diploma',
                    background: iconBackground,
                  }" direction-reverse />
        <mini-statistics-card title="Gold Token" value="$103,430" :percentage="{
          value: '+5%',
          color: 'text-success',
        }" :icon="{
                    component: 'ni ni-cart',
                    background: iconBackground,
                  }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">

      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">

      </div>
    </div>
    <!-- <div class="row mt-2">
      <div class="col-lg-12 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div v-if="!iframeLoaded">
              <Loader />
            </div>
             <iframe :src="iframeSrc" width="100%" height="400" frameborder="0" allowfullscreen
              @load="iframeLoaded = true"></iframe> 
          </div>
        </div>
      </div>
    </div>  -->
  </div>
</template>
<script>
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";

import axios from 'axios';


import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  computed: {
    userName() {
      return this.$store.getters.currentUser?.name || 'Guest';
    }
  },
  data() {
    return {
      iconBackground: "bg-gradient-warning",
      iframeSrc: 'https://blockchain.codeloop.co:4433/explorer/nodes',
      iframeLoaded: false,
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
      balance: 0,
      wallet: '',
    };
  },
  methods: {
    async fetchBalance() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URl}/api/wallet`, {
          // headers: {
          //   Authorization: `Bearer ${this.$store.state.token}`
          // }
        });
        // console.log({response})
        this.balance = response.data.wallet;
        // console.log({response})
        // console.log(this.balance)
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    async searchWallet() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URl}/api/bank`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        });
        this.wallet = response.data.bank.wallet.wallet_id;
        if (this.wallet != '') {
          this.fetchBalance();
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    }
  },
  created() {
    this.searchWallet();
  },
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    // GradientLineChart,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
};
</script>
<style>
.bg-gradient-orange {
  background-image: linear-gradient(310deg, #FFA500 0%, #FF8C00 50%, #FF4500 100%);
}
</style>