<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <main class="mt-0 main-content main-content-bg" style="text-align:center;">

            <!-- <Loader v-if="loading" />             -->

            <div class="card mb-4 mt-5 shadow">
                <div class="card-body pb-5">

                     <!-- <td class="align-middle">
                <div class="d-flex align-items-center justify-content-center"> -->

                    <div class="d-md-flex align-items-center justify-content-center mb-4">
                        <div class="p-2 d-flex align-items-center justify-content-center">
                            <div style="width:120px" ><img src="/bastion.svg" alt=""></div>
                        </div>
                        <div class="mt-md-4"><h1>Bastion</h1></div>
                    </div>
                    

                    <span class="fade-in" v-if="!error_message">Connecting...</span>
                    <p v-if="error_message">{{error_message}}</p>

                </div>
            </div>

        </main>
    </div>
</template>


<script>

export default {
    name: "SignIn",
    components: {
    },
    data() {
        return {
            email: '',
            // loading: true,
            emailGet: this.$route.query.email || null,
            uuid: this.$route.query.uuid || null,
            error_message: '',
            tries: 3
        };
    },
    created() {
        this.login();
    },
    methods: {
        checkConnection() {
            let self = this;
            if ( self.tries >= 1 ) {
                setTimeout(() => {
                    console.log('TRIES', self.tries)
                    self.tries--;
                    self.login();                    
                }, 3000);
            } else {
                self.error_message = 'Unable to access at this time, please try again later, if the issue continues, contact an administrator.'
            }
        },

        login() {
            let self = this;

            self.$store.dispatch('login', {
                email: self.emailGet,
                uuid: self.uuid
            }).then(response => {
                if (response.status === 200) {  // Verifique se o status da resposta é 200 (OK)
                    self.$router.push('/dashboard');
                } else {
                    // this.$root.notification('', 'Failed to logi1n')
                    self.checkConnection();
                }
            }).catch(() => {
                // this.$root.notification('', 'Failed to login2')
                // console.log(error);
                self.checkConnection();
            }).finally(() => {
                // this.loading = false;
            });
        },
    }
};
</script>

<style>
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 3s ease-in-out infinite;
}
</style>
