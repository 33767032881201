<template>
<div id="payment-content-comp">

  <div class="payment-splash" v-if="isLoading">Please wait</div>

  <form id="payment-form" @submit.prevent="handlePayment">
 
    <label for="email">E-mail</label>
    <input type="email" id="email" v-model="email" class="form-control mb-3" required placeholder="E-mail" readonly/>

    
    <label for="card-number-element">Card information</label>
    <div class="mb-3 pay-cad-information">
      
      <div id="card-number-element" style="height:40px" class="form-control div1"></div>
      <div class="d-flex">
        <div id="card-expiry-element" style="height:40px" class="form-control div2"></div>
        <div id="card-cvc-element" style="height:40px" class="form-control div3"></div>
      </div>
    </div>

    <label for="name">Cardholder name</label>
    <input type="text" id="name" v-model="cardholderName" placeholder="Full name on card" class="form-control mb-3" required />

    <button class="btn bg-gradient-success w-100 mt-3" :disabled="loading || isLoading">
      {{ loading || isLoading ? 'Processing...' : `Pay ${amount} ${currency}` }}
    </button>
    
  </form>

  <div v-if="message" class="alert alert-success mt-3">{{ message }}</div>
  <div v-if="error" class="alert alert-danger mt-3">{{ error }} </div>
</div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

export default {
   props: {
    id_code: {
       type: String,
       required: true,
    },
    amount: {
      type: Number,
      required: true, // Valor em centavos (exemplo: 2000 = $20.00)
    },
    currency: {
      type: String,
      default: 'usd',
    },
    backendUrl: {
      type: String,
      required: false, // URL do backend para processar o pagamento
    },
  },
  data() {
    return {
      stripe: null,
      elements: null,

      clientSecret: null,
      cardholderName: '',
      email: '',
      loading: false,
      message: '',
      error: '',

      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      isLoading: true
    };
  },
  async mounted() {
    // Inicializa o Stripe após o componente estar montado
    this.initializeStripe();
  },
  methods: {
    async initializeStripe() {

      try {

        // Inicializa o Stripe
        this.stripe = await loadStripe('pk_test_51QLNs4GKr9Wr3LWRJBD87bbtdk9oM7ed0lxmHtwQIPdK4eZbxsMjXP4rs2WdRpGHTCVBPlsawsZcgBcGDEU71IVE00Y2NJZa6l');

        this.elements = this.stripe.elements();

        // Criar elementos separados
        this.cardNumber = this.elements.create('cardNumber');
        this.cardExpiry = this.elements.create('cardExpiry');
        this.cardCvc    = this.elements.create('cardCvc');
        

        // Montar os elementos nos respectivos contêineres
        this.cardNumber.mount('#card-number-element');
        this.cardExpiry.mount('#card-expiry-element');
        this.cardCvc.mount('#card-cvc-element');

        let self = this;

        // Obtém o clientSecret do backend
        axios
          .post(`${process.env.VUE_APP_API_URl}/api/payment-intent`, { invoice_code: self.id_code })
          .then((response) => {
            console.log(response.data);
            this.clientSecret = response.data.clientSecret;
            this.email = response.data.email;
          })
          .catch((error) => {
            // this.error = 'Erro ao inicializar o pagamento: ' + error.message;

            self.$root.notification('Erro', error.message)
          });


      // Adiciona um evento para detectar quando o cartão está totalmente renderizado
        self.cardCvc.on('ready', () => {
          self.isLoading = false; // Muda o estado somente quando tudo está realmente pronto
        });


      } catch (error) {
        console.error('Erro ao carregar o Stripe:', error);
      }

    },
    async handlePayment() {
      this.loading = true;

      let self = this;

      try {
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: this.cardholderName,
              email: this.email,
            },
          },
        });

        if (error) {
          // this.error = error.message;
          self.$root.notification('Atention', error.message)
        } else if (paymentIntent.status === 'succeeded') {
          // this.message = 'Pagamento realizado com sucesso!';
          self.$root.notification('Uhull', 'Payment made successfully!')
          console.log(paymentIntent);

          axios.post(`${process.env.VUE_APP_API_URl}${self.backendUrl}`, paymentIntent).then((response) => {
            if (response.data.status == 'success') {
              self.$router.replace({ path: "/billing", query: {} });
            } else {
              self.$root.notification('Erro', response.data.message)
            }
            
          })
          .catch((error) => {
            self.$root.notification('Erro', error.message)
          });

        }
      } catch (err) {
        // this.error = 'Erro ao processar o pagamento: ' + err.message;
        self.$root.notification('Ops', err.message)
      }

      this.loading = false;
    },
  },
};
</script>

<style>

  #card-element {
    border: 1px solid #ced4da;
    padding: 10px;
    border-radius: 4px;
  }

  .pay-cad-information {
    border:1px solid #d2d6da;
    border-radius: 0.5rem;
  }

  

  .pay-cad-information .div1 {
   width: 100%;
   height: 40px;
   border-bottom: 1px solid #d2d6da !important;
  }

   .pay-cad-information .div2 {
    width: 100%;
   height: 40px;
   border-right: 1px solid #d2d6da !important;
   }
  .pay-cad-information .div3 {
    width: 100%;
    height: 40px;
   }

  .pay-cad-information .form-control {
    border: unset;
    border-radius: unset;
    border: unset;
    background-color: unset
   }

   


  #payment-content-comp {
    position: relative;
    border:1px solid #d2d6da;
    border-radius: 0.5rem;
    padding: 14px;
  }

  #payment-content-comp .payment-splash {
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: white;
  }
</style>
